export const URL_ROUTES = {
  login: "login",
  demo: "demo",
  newCampaign: "new-sales-campaign",
  campaignHistory: "campaign-history",
  payment: "payments",
  voiceSettings: "voice-settings",
};

export const API_BASE_URL = "http://13.60.196.125/";
export const API_ENDPOINT = {
  login: "auth/login",
  register: "auth/register",
  profile: "auth/profile",
  createCampaign: "campaign/create-campaign/",
  listCampaign: "campaign/list-campaign",
  getCampaign: "campaign/get-campaign",
  updateCampaign: "campaign/update-campaign",
  deleteCampaign: "campaign/delete-campaign",
  startCampaign: "campaign/start-campaign",
};
