import { jwtDecode } from "jwt-decode";
import { toastError } from "../components/Toast/Toast";

export const getToken = () => {
  const loginData = localStorage.getItem("loginData") || null;
  if (loginData) {
    return JSON.parse(loginData).access_token;
  }
  return loginData;
};

export const getSessionData = () => {
  const loginData = localStorage.getItem("loginData") || null;
  if (loginData) {
    return JSON.parse(loginData);
  }
  return loginData;
};

export const isTokenExpired = () => {
  const token = getToken();
  if (token) {
    const decodedToken = jwtDecode(token);
    // console.log(token, decodedToken, decodedToken * 1000 < Date.now());
    return decodedToken * 1000 < Date.now();
  } else {
    return true;
  }
};

export const logout = () => {
  localStorage.removeItem("loginData");
  toastError("Token has Expired!.");
};
