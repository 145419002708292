import React, { useEffect } from "react";
import { Route, Routes, Outlet, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import Pages
import "./App.css";
import Login from "./pages/Login/Login";
import Header from "./pages/Header/Header";
import Sidebar from "./pages/Sidebar/Sidebar";
import Demo from "./pages/Demo/Demo";
import Payments from "./pages/Payments/Payments";
import NewSalesCampaign from "./pages/NewCampaign/NewCampaign";
import VoiceSettings from "./pages/VoiceSettings/VoiceSettings";
import CampaignHistory from "./pages/CampaignHistory/CampaignHistory";

// import Components
import { URL_ROUTES } from "./utils/constants";
import { getToken, isTokenExpired, logout } from "./services/sessionServices";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  /* START: check login session for every route change ---------------------------------- */
  const token = getToken();
  useEffect(() => {
    if (!token) {
      navigate(`/${URL_ROUTES.login}`);
    } else {
      if (isTokenExpired()) {
        logout();
        navigate(`/${URL_ROUTES.login}`);
      }
    }
  }, [navigate, location]);
  /* END: check login session for every route change ------------------------------------ */

  /* START: get profile details --------------------------------------------------------- */
  /* useEffect(() => {
    getProfileDetails();
  }, [loginData]);

  const getProfileDetails = () => {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${loginData.access_token}`,
    };
    axios
      .get(`${API_BASE_URL}${API_ENDPOINT.profile}`, { headers: headers })
      .then((response) => {
        response.data.data.password = "";
        localStorage.setItem("profile", JSON.stringify(response.data.data));
      })
      .catch((error) => {
        console.error("profile", error);
      });
  }; */
  /* END: get profile details ----------------------------------------------------------- */

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <Outlet />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route path="new-sales-campaign" element={<NewSalesCampaign />} />
        <Route path="campaign-history" element={<CampaignHistory />} />
        <Route path="payments" element={<Payments />} />
        <Route path="voice-settings" element={<VoiceSettings />} />
        <Route path="demo" element={<Demo />} />
      </Route>
    </Routes>
  );
};
export default App;
