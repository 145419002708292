import React, { useState, useEffect } from "react";
import axios from "axios";

import Loading from "../../components/Loading/Loading";
import { toastSuccess, toastError } from "../../components/Toast/Toast";
import { getToken } from "../../services/sessionServices";
import { API_BASE_URL, API_ENDPOINT } from "../../utils/constants";

import classes from "./CampaignHistory.module.css";

const CampaignHistory = () => {
  const token = getToken();
  const [isLoading, setIsLoading] = useState(false);

  /* START: List Campaigns by Login user ------------------------------------------------ */
  const [campaignList, setCampaignList] = useState(null);
  useEffect(() => {
    !campaignList && token && callGetCampaignListAPI();
  }, []);

  const callGetCampaignListAPI = () => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    // toastInfo("Loading data, please wait.");
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.listCampaign}/?skip=0&limit=100`, { headers: headers })
        .then((response) => {
          if (response?.data?.success) {
            setCampaignList(response.data.data);
          } else {
            toastError("Failed to Refresh Campaign List.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toastError("Failed to load Campaign List.");
          setIsLoading(false);
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: List Campaigns by Login user -------------------------------------------------- */

  /* START: View details of each campaign------------------------------------------------ */
  const [campaignId, setCampignId] = useState(null);
  const [campaignData, setCampignData] = useState(null);
  const handleCampignClick = (id) => {
    setCampignId(id);
    if (id) {
      getCampignDataById(id);
    } else {
      setCampignData(null);
    }
  };

  const [activeTab, setActiveTab] = useState("Details");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getCampignDataById = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.getCampaign}/${id}`, { headers: headers })
        .then((response) => {
          if (response?.data.success) {
            setCampignData(response.data.data);
          } else {
            toastError("Failed to load Campaign Data.");
          }
        })
        .catch((error) => {
          toastError("campaign data by id");
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };

  /* END: View details of each campaign-------------------------------------------------- */

  /* START: Start Campaign by Id : API call --------------------------------------------- */
  const handleStartCampignClick = (id) => {
    callStartCampaignAPI(id);
  };
  const callStartCampaignAPI = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.startCampaign}/${id}`, "", { headers })
        .then((response) => {
          if (response?.data.success) {
            setCampignData(response.data.data);
            toastSuccess("Campaign has Started Succesfully.");
            callGetCampaignListAPI();
          } else {
            const errorMsgs = [];
            if (response?.data?.errors) {
              response.data.errors.forEach((x) => errorMsgs.push(x.message));
              toastError(errorMsgs.join("<br/>"));
            } else {
              toastError("Failed to start campaign.");
            }
          }
        })
        .catch((error) => error && toastError(error.response?.data?.message || error.response?.data?.detail || "Failed to start campaign"));
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: Start Campaign by Id----------------------------------------------------------- */
  if (isLoading) return <Loading />;
  return (
    <div className="campaign-history p-2">
      {/* START: List Campaigns by Login user ------------------------------------------- */}
      {!campaignId && (
        <table className="table table-sm table-striped table-responsive">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Company Name</th>
              <th>Product Name</th>
              <th>No. Of Calls</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {campaignList &&
              campaignList.campaigns.map((x) => (
                <tr key={x.camp_id}>
                  <td>{x.camp_id}</td>
                  <td>{x.campaign_name}</td>
                  <td>{x.company_name}</td>
                  <td>{x.product_name}</td>
                  <td>0</td>
                  <td>
                    <span className={`badge p-1 rounded ${x.is_running ? "text-bg-primary" : "text-bg-secondary"}`}>{x.is_running ? "Started" : "Not Started"}</span>
                  </td>
                  <td>
                    {!x.is_running && (
                      <button className="btn btn-sm bg-success text-white me-1" onClick={(e) => handleStartCampignClick(x.camp_id)}>
                        Start
                      </button>
                    )}
                    <button className="btn btn-sm bg-body-secondary" onClick={(e) => handleCampignClick(x.camp_id)}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {/* END: List Campaigns by Login user --------------------------------------------- */}

      {/* START: View Campaign details by Campaign Id ----------------------------------- */}
      {campaignId && (
        <>
          <a className="" onClick={(e) => handleCampignClick(null)}>
            Back to List
          </a>
          <div className={classes.tabs}>
            <button className={`${classes.tab} ${activeTab === "Details" ? classes.active : ""}`} onClick={() => handleTabClick("Details")}>
              Details
            </button>
            <button className={`${classes.tab} ${activeTab === "Outcome" ? classes.active : ""}`} onClick={() => handleTabClick("Outcome")}>
              Outcome
            </button>
            <button className={`${classes.tab} ${activeTab === "Analytics" ? classes.active : ""}`} onClick={() => handleTabClick("Analytics")}>
              Analytics
            </button>
          </div>

          <div className={classes.tab_content}>
            {activeTab === "Details" && (
              <div>
                <h2>Campaign Details</h2>
                <h2>{campaignId}</h2>
                <p>Here you can add details about the campaign, such as objectives, timeline, etc.</p>
              </div>
            )}
            {activeTab === "Outcome" && (
              <div>
                <h2>Campaign Outcome</h2>
                <p>Here you can review the results and achievements of the campaign.</p>
              </div>
            )}
            {activeTab === "Analytics" && (
              <div>
                <h2>Campaign Analytics</h2>
                <p>Here you can analyze in-depth data and performance indicators.</p>
              </div>
            )}
          </div>
        </>
      )}
      {/* END: View Campaign details by Campaign Id ------------------------------------- */}
    </div>
  );
};

export default CampaignHistory;
