import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classes from "./Demo.module.css";

import { getSessionData } from "../../services/sessionServices";
import { toastSuccess, toastError } from "../../components/Toast/Toast";
import { API_BASE_URL, API_ENDPOINT, URL_ROUTES } from "../../utils/constants";

const Demo = () => {
  const navigate = useNavigate();
  const sessionData = getSessionData();

  /* Start: Form related opeartions------------------------------------------------------ */
  // read field input values
  const [campignData, setCampignData] = useState({ campaignName: "", companyName: "", companyDetail: "", productName: "", productDetail: "", voiceLang: "", voiceName: "" });
  const handleInputChange = (event) => {
    setCampignData({ ...campignData, [event.target.name]: event.target.value });
  };

  // handle phone number
  const [phoneNumbers, setPhoneNumbers] = useState([""]);
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
  };
  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  // handle selling points
  const [sellingPoints, setSellingPoint] = useState([""]);
  const handleAddSellingPoint = () => {
    setSellingPoint([...sellingPoints, ""]);
  };

  const handleSellingPointChange = (index, value) => {
    const newSellingPoint = [...sellingPoints];
    newSellingPoint[index] = value;
    setSellingPoint(newSellingPoint);
  };

  // handle questions
  const [questions, setQuestion] = useState([""]);
  const handleAddQuestion = () => {
    setQuestion([...questions, ""]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestion = [...questions];
    newQuestion[index] = value;
    setQuestion(newQuestion);
  };

  // prepare final api payload
  const handleCreateCampaignSubmit = (event) => {
    const payload = {
      campaign_name: campignData.campaignName,
      company_name: campignData.companyName,
      company_detail: campignData.companyDetail,
      product_name: campignData.productName,
      product_detail: campignData.productDetail,
      voice_lang: campignData.voiceLang,
      voice_name: campignData.voiceName,
      is_running: false,
      user_id: sessionData.id,
      questions: questions,
      phone_numbers: phoneNumbers,
      selling_points: sellingPoints,
    };

    // console.log("Campaign Started:", payload);

    event.preventDefault();
    callCreateCampaignAPI(payload);
  };

  const callCreateCampaignAPI = (payload) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionData.access_token}`,
    };

    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.createCampaign}`, payload, { headers })
        .then((response) => {
          if (response?.data.success) {
            toastSuccess(response?.data?.message);
            navigate(`/${URL_ROUTES.campaignHistory}`);
          } else {
            const errorMsgs = [];
            if (response?.data?.errors) {
              response.data.errors.forEach((x) => errorMsgs.push(x.message));
              toastError(errorMsgs.join("<br/>"));
            } else {
              toastError("Failed to Create Campaign.");
            }
          }
        })
        .catch((error) => {
          toastError(error.response.data?.message || error.response.data?.detail);
        });
    } catch (e) {
      toastError("Something went wrong, please try again!");
    }
  };

  return (
    <form onSubmit={handleCreateCampaignSubmit}>
      <div className={classes.demo_container}>
        <div className={classes.column}>
          <div className={classes.section}>
            <div className={classes.form_group}>
              <label htmlFor="campaignName">Name of the Campaign</label>
              <input type="text" id="campaignName" name="campaignName" placeholder="Enter campaign name" onChange={handleInputChange} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.demo_container}>
        <div className={classes.column}>
          {/* First Row - Organisation Detail */}
          <div className={classes.section}>
            <h5>Organisation Detail</h5>
            <div className={classes.form_group}>
              <label htmlFor="companyName">Name of the Company (in short)</label>
              <input type="text" id="companyName" name="companyName" placeholder="Enter company name" onChange={handleInputChange} />
            </div>
            <div className={classes.form_group}>
              <label htmlFor="companyDetail">Detail of the Company</label>
              <textarea id="companyDetail" name="companyDetail" placeholder="Enter company details" onChange={handleInputChange}></textarea>
            </div>
          </div>

          {/* Second Row - Product Detail */}
          <div className={classes.section}>
            <h5>Product Detail</h5>
            <div className={classes.form_group}>
              <label htmlFor="productName">Name of the Product (in short)</label>
              <input type="text" id="productName" name="productName" placeholder="Enter product name" onChange={handleInputChange} />
            </div>
            <div className={classes.form_group}>
              <label htmlFor="productDetail">Detail of the Product</label>
              <textarea id="productDetail" name="productDetail" placeholder="Enter product details" onChange={handleInputChange}></textarea>
            </div>
            <div className={classes.form_group}>
              <label htmlFor="sellingPoints">Main Selling Points (comma separated) Max 5</label>
              {sellingPoints.map((sellingPoint, index) => (
                <div className={classes.form_group} key={index}>
                  <small>
                    <label htmlFor={`phoneNumber${index}`}> Selling Point {index + 1}</label>
                  </small>
                  <input type="text" id="sellingPoint" value={sellingPoint} placeholder="Enter selling point" onChange={(e) => handleSellingPointChange(index, e.target.value)} />
                </div>
              ))}
              {sellingPoints.length < 5 && (
                <a className="float-end text-decoration-none" onClick={handleAddSellingPoint}>
                  <small>Add More...</small>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className={classes.column}>
          {/* First Row - Phone Numbers */}
          <div className={classes.section}>
            <h5>Phone Numbers</h5>
            {phoneNumbers.map((phoneNumber, index) => (
              <div className={classes.form_group} key={index}>
                <small>
                  <label htmlFor={`phoneNumber${index}`}>Number {index + 1}</label>
                </small>
                <input type="text" id={`phoneNumber${index}`} value={phoneNumber} onChange={(e) => handlePhoneNumberChange(index, e.target.value)} placeholder="Enter phone number" />
              </div>
            ))}
            <a className="float-end text-decoration-none" onClick={handleAddPhoneNumber}>
              <small>Add More...</small>
            </a>
          </div>

          {/* Second Row - Voice Settings */}
          <div className={classes.section}>
            <h5>Voice Settings</h5>
            <div className={classes.column}>
              <div className={classes.form_group}>
                <label htmlFor="voiceLang">Select Language</label>
                <select id="voiceLang" name="voiceLang" onChange={handleInputChange}>
                  <option value="">Select Language</option>
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                </select>
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.form_group}>
                <label htmlFor="voiceName">Select Voice</label>
                <select id="voiceName" name="voiceName" onChange={handleInputChange}>
                  <option value="">Select Voice</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <h5>What information you want from interested customers?</h5>
            <div className={classes.form_group}>
              {questions.map((question, index) => (
                <div className={classes.form_group} key={index}>
                  <small>
                    <label htmlFor={`question${index}`}> Question {index + 1}</label>
                  </small>
                  <input type="text" id="question" value={question} placeholder="Enter question" onChange={(e) => handleQuestionChange(index, e.target.value)} />
                </div>
              ))}
              <a className="float-end text-decoration-none" onClick={handleAddQuestion}>
                <small>Add More...</small>
              </a>
            </div>
          </div>
          <div className={classes.section}>
            <button type="submit" className="btn btn-primary">
              Create Campaign
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Demo;
